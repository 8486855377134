import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import { Link } from "react-router-dom";

export const HowItWorks = () => {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h2" mb={1}>
            Your phone does the work!
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Share you device sensor data 100% anonymously and earn money from businesses that buy
            this data in aggregate.
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon="download"
              title="Install App"
              description="Download and install the app on your device, and allow it to access your location and sensors."
              direction="center"
              iconLink="#download"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon="share"
              title="Share data"
              description="Your phone's data, such as longitude, latitude, surrounding noise, and atmospheric pressure, will be uploaded and made available for data consumers."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              icon="attach_money"
              title="Get paid"
              description="Meteorologists, app builders, researchers and anyone that might find your data useful buy it from you."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};
